import React from 'react'
import styles from '../styles/TestSetThumb.css'
import {ReactComponent as User} from '../images/user.svg'
import {ReactComponent as Tests} from '../images/tests.svg'
import {ReactComponent as Warning} from '../images/warning.svg'

/*
  props: {
    id,
    date,
    location,
    locationCoordinates,
    condition,
    risk, // low/medium/high
    confidence,
    onClickTestSetThumb // takes location coordinates as array of percentages
    isAIActive // hides ai features when true
  }
*/

function TestSetThumb(props) {
  return (
    <div
      className={
        props.isClicked ?
        `${styles.testSetThumb} ${styles.clickedTestSetThumb}` :
        styles.testSetThumb
      }
    >
      <div className={styles.top}>
        <p className={styles.fontSmall}>
          {`Test ID: ${props.id}`}
        </p>
        <div>
          <p>{props.date}</p>
          {props.type === 'mproc' ? <Tests /> : <User />}
        </div>
      </div>
      <div className={styles.middle}>
        {props.potentialConcern && (
          <p className={styles.fontLarge}>⚠️</p>
        )}
        <p className={styles.fontLarge}>
          {props.location}
        </p>
      </div>
      {props.isAIActive &&
        <div className={styles.bottom}>
          <div className={styles.conditionDiv}>
            <div
              className={`${styles.conditionCircle} ${styles[props.risk]}`}
            />
            <p className={`${styles.condition} ${styles.fontSmall} ${styles.bold}`}>{props.condition}</p>
          </div>
          <p className={`${styles.fontSmall} ${styles.bold}`}>{`${props.confidence}%`}</p>
        </div>
      }
    </div>
  )
}

export default TestSetThumb
