import React, {Fragment} from 'react'
import Button from './Button'
import XBoxContainer from './XBoxContainer'
import styles from '../styles/Notification.css'

/*
  props:
    {
      type: 1-dismissable persistant message ||
            2-timed out message ||
            3-centered dismissable persistant modal with overlay
      isVisible: boolean to trigger animations
      image: (icon in upper left corner)
      time: (upper right corner)
      message,
      *onClick: (optional, for dismissing)
      *style: (optional)
    }
*/

export default function Notification(props) {
  const notificationClass = () => {
    let defaultClass = styles.notification
    if (props.type === 3) {
      defaultClass += ` ${styles.modal}`
      if (!props.isVisible) {
        defaultClass += ` ${styles.slideOutRightCenter}`
      }
    } else if (!props.isVisible) {
      defaultClass += ` ${styles.slideOutRight}`
    }
    console.log('default class', defaultClass)
    return defaultClass
  }
  return (
    <Fragment>
      <div
        style={props.style || {}}
        className={notificationClass()}
      >
        <div className={styles.relativeDiv}>
          {props.type !== 3 && <XBoxContainer onClick={props.onClick} />}
          <div className={styles.notificationTop}>
            <img src={props.image} />
            <p className={styles.time}>{props.time}</p>
          </div>
          <div>
            <p className={styles.message}>{props.message}</p>
          </div>
          {props.type === 3 && (
            <Button text='Dismiss' type={2} onClick={props.onClick} />
          )}
        </div>
      </div>
      {props.type === 3 &&
        props.isVisible && (
          <div className={styles.grayScreen} />
        )
      }
    </Fragment>
  )
}
