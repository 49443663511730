import React from 'react'
import styles from '../styles/PatientThumb.css'

function PatientThumb(props) {
  const initials = () => {
    let firstLetter
    let secondLetter
    if (props.firstName && props.lastName) {
      firstLetter = props.firstName[0].toUpperCase()
      secondLetter = props.lastName[0].toUpperCase()
    } else {
      firstLetter = props.name[0].toUpperCase()
      const secondLetterIndex = props.name.indexOf(' ') + 1
      secondLetter = props.name[secondLetterIndex].toUpperCase()
    }
    return `${firstLetter}${secondLetter}`
  }
  return (
    <div
      className={
        props.isClicked ?
        `${styles.patientThumb} ${styles.clickedPatientThumb}` :
        styles.patientThumb
      }
    >
      <div className={styles.imageDiv}>
        {props.image ? (
          <img className={styles.image} src={props.image} />
        ) : (
          <p className='initials'>{initials()}</p>
        )}
      </div>
      <div className={styles.nameDiv}>
        <p>{props.name.length > 30 ? props.name.slice(0, 30) + '...' : props.name}</p>
        {props.isLinked &&
          <pre className={styles.isLinked}>
            Patient Portal Access
          </pre>
        }
      </div>
      <div className={styles.birthdateDiv}>
        <p>Date of Birth</p>
        <p>{props.birthdate}</p>
      </div>
      <div className={styles.lastUpdatedDiv}>
        <p>Last Updated</p>
        <p>{props.lastUpdated}</p>
      </div>
      {props.newTests && props.newTests > 0 && (
        <div className={styles.newTestsOrb}>
          <p>{props.newTests > 0 && props.newTests}</p>
        </div>
      )}
    </div>
  )
}

export default PatientThumb
