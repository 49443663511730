import React from 'react'
import styles from '../styles/OrgMemberThumb.css'

/*
  props: {
    onClick: f(),
    image: (optional),
    isSelected: boolean,
    orgMember: {
      Info: {
        Name: 'string',
        Email: 'string'
      },
      Membership: {
        Auth: 'string'
      }
    }
  }
*/

function OrgMemberThumb(props) {
  const initials = () => {
    if (props.orgMember) {
      const firstLetter = props.orgMember.Info.Name[0].toUpperCase()
      const secondLetterIndex = props.orgMember.Info.Name.indexOf(' ') + 1
      const secondLetter = props.orgMember.Info.Name[secondLetterIndex].toUpperCase()
      return `${firstLetter}${secondLetter}`
    }
  }
  return (
    <div
      className={
        props.isSelected ?
        `${styles.orgMemberThumb} ${styles.selected}` :
        styles.orgMemberThumb
      }
      onClick={props.onClick}
    >
      <div className={styles.imageDiv}>
        {props.image ? (
          <img src={props.image} />
        ) : (
          <p className='initials'>{initials()}</p>
        )}
      </div>
      <div className={styles.infoDiv}>
        <p>{props.orgMember.Info.Name}</p>
        <p className={styles.Email}>{props.orgMember.Info.Email}</p>
        <div className={styles.authDiv}>
          <p className={styles.auth}>{props.orgMember.Membership.Auth}</p>
        </div>
      </div>
    </div>
  )
}

export default OrgMemberThumb
