import React, {Fragment} from 'react'
import Button from './Button'
import XBoxContainer from './XBoxContainer'
import styles from '../styles/Modal.css'

/*
  props:
    {
      isVisible: boolean to trigger animations
      image: (icon in upper left corner)
      time: (upper right corner)
      message,
      confirmationText,
      confirmationFunc,
      cancelationText,
      cancelationFunc,
      *style: (optional)
    }
*/

export default function Modal(props) {
  const notificationClass = () => {
    let defaultClass = styles.modal
    if (!props.isVisible) {
      defaultClass += ` ${styles.slideOutRightCenter}`
    }
    console.log('default class', defaultClass)
    return defaultClass
  }
  return (
    <Fragment>
      <div
        style={props.style || {}}
        className={notificationClass()}
      >
        <div className={styles.modalContent}>
          <div className={styles.notificationTop}>
            <img src={props.image} />
            <p className={styles.time}>{props.time}</p>
          </div>
          <div>
            <p className={styles.message}>{props.message}</p>
          </div>
        </div>
        <div className={styles.btnDiv}>
          <Button
            text={props.cancelationText}
            type={2}
            onClick={props.cancelationFunc}
            style={{width: '50%', marginRight: '0.25em'}}
          />
          <Button
            text={props.confirmationText}
            type={1}
            onClick={props.confirmationFunc}
            style={{width: '50%', marginLeft: '0.25em'}}
          />
        </div>
      </div>
      {props.isVisible && (
        <div className={styles.grayScreen} />
      )}
    </Fragment>
  )
}
