import React from 'react'
import styles from '../styles/TestSetThumb.css'
import {ReactComponent as MoleTrackingIcon} from '../images/moleTrackingIcon.svg'

/*
  props: {
    id,
    date,
    location,
    locationCoordinates,
    condition,
    risk, // low/medium/high
    confidence,
    onClickTestSetThumb // takes location coordinates as array of percentages
    isReviewRequested: boolean,
    imageNumber: int,
  }
*/

function MoleTrackingThumb(props) {
  return (
    <div
      className={
        props.isClicked ?
        `${styles.testSetThumb} ${styles.clickedTestSetThumb}` :
        styles.testSetThumb
      }
    >
      <div className={styles.top}>
        <p className={styles.fontSmall}>
          {`Test ID: ${props.id}`}
        </p>
        <div>
          <p>
            {props.date1} - {props.date2}
          </p>
          <MoleTrackingIcon />
        </div>
      </div>
      <div className={styles.middle}>
        {props.potentialConcern && (
          <p className={styles.fontLarge}>⚠️</p>
        )}
        <p className={styles.fontLarge}>
          {props.location}
        </p>
      </div>
      <div className={styles.moleTrackingBottom}>
        <span>{props.imageNumber === 1 ? props.imageNumber + ' IMAGE' : props.imageNumber + ' IMAGES'}</span>
        {props.isReviewRequested &&
          <span className={styles.blueHighlight}>
            REVIEW REQUESTED
          </span>
        }
      </div>
    </div>
  )
}

export default MoleTrackingThumb
