import React from 'react'
import styles from '../styles/PatientHeader.css'
import { ReactComponent as User } from '../images/user.svg'

function PatientHeader(props) {
  const initials = () => {
    const firstLetter = props.name[0].toUpperCase()
    const secondLetterIndex = props.name.indexOf(' ') + 1
    const secondLetter = props.name[secondLetterIndex].toUpperCase()
    return `${firstLetter}${secondLetter}`
  }
  return (
    <header
      className={styles.patientHeader}
      style={props.style || {}}
    >
      <div className={styles.imageDiv} onClick={props.onClick || null}>
        {props.image ? (
          <img className={styles.image} src={props.image} />
        ) : (
          <p className='initials'>{initials()}</p>
        )}
      </div>
      <div className={styles.nameDiv} onClick={props.onClick || null}>
        <p className={styles.fontLarge}>{props.name}</p>
      </div>
      {props.sex && (
        <div className={`${styles.sexDiv} ${styles.infoDiv}`} onClick={props.onClick || null}>
          <p>Sex</p>
          <p className={`${styles.fontLarge} ${styles.capitalize}`}>{props.sex}</p>
        </div>
      )}
      <div onClick={props.onClick || null} className={`${styles.birthdateDiv} ${styles.infoDiv}`}>
        <p>Date of Birth</p>
        <p className={styles.fontLarge}>{props.birthdate}</p>
      </div>
      <div onClick={props.onClick || null} className={`${styles.lastUpdatedDiv} ${styles.infoDiv}`}>
        <p>Last Updated</p>
        <p className={styles.fontLarge}>{props.lastUpdated}</p>
      </div>
      <div className={styles.sharePatientBtnContainer}>
        <div
          className={styles.sharePatientBtn}
          onClick={props.onClickSharePatient}
        >
          <User />
          <p>+</p>
        </div>
      </div>
      {props.newTests && props.newTests > 0 && (
        <div className={styles.newTestsOrb}>
          <p>{props.newTests > 0 && props.newTests}</p>
        </div>
      )}
    </header>
  )
}

export default PatientHeader
