import React from 'react'
import styles from '../styles/Header.css'

function Header(props) {
  return (
    <header className={styles.physicianHeader}>
      <div className={styles.imageDiv}>
        <img src={props.image} alt='photo' />
      </div>
      <div className={styles.nameDiv}>
        <p>Welcome,</p>
        <p className={styles.fontMedium}>{props.name}</p>
      </div>
      <div className={styles.practiceDiv}>
        <p className={styles.grayText}>Practice</p>
        <p className={styles.practiceName}>{props.practice}</p>
      </div>
    </header>
  )
}

export default Header
