import React from 'react'
import styles from '../styles/XBoxContainer.css'
import {ReactComponent as XBox} from '../images/Xbox.svg'

export default function XBoxContainer(props) {
  return (
    <div
      style={props.style || {}}
      className={styles.xBoxContainer}
      onClick={props.onClick}
    >
      <XBox />
    </div>
  )
}
