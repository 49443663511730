import React from 'react'
import styles from '../styles/TestThumb.css'
import {ReactComponent as Flag} from '../images/flag.svg'
import {ReactComponent as Notes} from '../images/notes.svg'
import ConfidenceBar from './ConfidenceBar.js'

function TestThumb(props) {
  return (
    <div
      className={
        props.isShowing ?
        `${styles.testThumb} ${styles.expandDown}` :
        `${styles.testThumb} ${styles.collapseUp}`
      }
      style={props.isShowing ? props.style : {}}
    >
      <div className={styles.imageDiv}>
        <img src={props.image} alt='lesion' />
      </div>
      {props.isAIActive &&
        <div className={styles.textDiv}>
          <div className={styles.topDiv}>
            <div className={styles.leftDiv}>
              <p>Top Results</p>
              <div className={styles.flex}>
                <div
                  className={
                    `${styles.conditionCircle} ${styles[props.risk1]}`
                  }
                />
                <p className={styles.condition1}>{props.condition1}</p>
              </div>
              <div className={styles.flex}>
                <div
                  className={
                    `${styles.conditionCircle} ${styles[props.risk2]}`
                  }
                />
                <p className={styles.condition2}>{props.condition2}</p>
              </div>
            </div>
            <div className={styles.middleDiv}>
              <p>Confidence</p>
              <p className={styles.condition1}>{`${props.confidence1}%`}</p>
              <p className={styles.condition2}>{`${props.confidence2}%`}</p>
            </div>
            <div className={styles.rightDiv}>
              <div>
                {props.notes && <Notes />}
              </div>
              <div>
                {props.flag && <Flag />}
              </div>
            </div>
          </div>
          <div className={styles.bottomDiv}>
            <ConfidenceBar
              reasoning={props.reasoning}
            />
          </div>
        </div>
      }
    </div>
  )
}

export default TestThumb
