import React, { useState } from 'react'
import styles from '../styles/SexInput.css'
import Button from './Button'

/*
  props: {
    *style,
    *disabled,
    onChange= f(),
    sex: 'male' || 'female',
    value: 'male' || 'female'
  }
*/

function SexInput(props) {
  const onChange = (event, option) => {
    event.preventDefault()
    props.onChange(option)
  }
  return (
    <div className={styles.sexInput} style={props.style || {}}>
      <p>Sex</p>
      <div className={styles.buttonDiv}>
        <Button
          type={
            (props.sex === 'female' || props.value === 'female') ? 1 : 2
          }
          text='Female'
          onClick={(event) => {
            event.preventDefault()
            if (!props.disabled) {
              onChange(event, 'female')
            }
          }}
          width='48%'
        />
        <Button
          type={
            (props.sex === 'male' || props.value === 'male') ? 1 : 2
          }
          text='Male'
          onClick={(event) => {
            event.preventDefault()
            if (!props.disabled) {
              onChange(event, 'male')
            }
          }}
          width='48%'
        />
      </div>
    </div>
  )
}

export default SexInput
