import React from 'react'
import styles from '../styles/AsideDiv.css'
import Switch from './Switch.js'

/*
  props: {
    isSelected,
    type, // 1 or 2 for text sizing
    onClick,
    text,
    number,
    switchText,
    *image,
    *onClickImage,
    *render // For popuptab
    *invertColor // boolean for black/white background/text
  }
*/

function AsideDiv(props) {
  const asideDivClass = () => {
    let className = `${styles.asideDiv}`
    if (props.invertColor) {
      className += ` ${styles.invertColor}`
      if (props.isSelected) {
        className += ` ${styles.invertedSelectedAsideDiv}`
      }
    } else if (props.isSelected) {
      className += ` ${styles.selectedAsideDiv}`
    }
    if (props.type === 1) {
      className += ` ${styles.type1}`
    }
    return className
  }

  return (
    <div
      className={asideDivClass()}
      style={props.style || {}}
      onClick={props.onClick}
    >
      {props.render && props.render}
      <div className={styles.flex}>
        <p>{props.text}</p>
        <div className={styles.flex}>
          <p className={styles.smallText}>
            {props.switchText && props.switchText}
          </p>
          {props.image && (
            <img
              src={props.image}
              alt=''
              onClick={props.onClickImage}
            />
          )}
        </div>
      </div>
      <div className={`${styles.flex} ${styles.alignCenter}`}>
        <p
          className={
            props.type === 1 ?
            styles.bigText :
            styles.mediumText
          }
        >
          {props.number}
        </p>
        {props.switch && (
          <Switch onChange={props.onChange} checked={props.isSelected} />
        )}
      </div>
    </div>
  )
}

export default AsideDiv

