import React from 'react'
import styles from '../styles/Switch.css'

/* props: {
    onChange,
    checked
  }
*/

function Switch(props) {
  return (
    <label className={styles.switch}>
      <input
        className={styles.input}
        type='checkbox'
        onChange={props.onChange}
        checked={props.checked}
      />
      <span className={styles.slider} />
    </label>
  )
}

export default Switch
