import React from 'react'
import styles from '../styles/Loader.css'

function Loader(props) {
  return (
    <svg className={styles.loader} viewBox='25 25 50 50' style={props.style || {}}>
      <circle className={`${styles[props.color]} ${styles.circle}`} cx='50' cy='50' r='20' />
    </svg>
  )
}

export default Loader
