import React, {Fragment} from 'react'
import Loader from './Loader'
import styles from '../styles/Button.css'

/*
  props: {
    type: 1 || 2, // 1: black btn, 2: white w/ black border
    text,
    onClick,
    width,
  }
*/

const Button = ({ style = {}, text, onClick, width = '100%', type = 1, isLoading, image = null }) => {
  return (
    <button
      className={
        type === 1 ?
        `${styles.button} ${styles.button1}` :
        `${styles.button} ${styles.button2}`
      }
      onClick={!isLoading && onClick}
      style={{ ...style, width }}
    >
      {isLoading ?
        <Loader
          color='white'
          style={{height: '2em', width: '2em'}}
        /> : (
          image ? (
            <Fragment>
              <img src={image} />
              <p style={{margin: 0}}>{text}</p>
            </Fragment>
          ) :
          text
        )
      }
    </button>
  )
}

export default Button
