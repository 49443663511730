import React from 'react'
import styles from '../styles/FloatingButton.css'

/*
  props: {
    onClick: f(),
    style: {} (optional),
    image: (optional),
    text: 'string',
    label: 'string', (optional)
    tooltipStyle: {} (optional),
  }
*/

function FloatingButton(props) {
  return (
    <div
      className={
        props.text === 'cancel' ? (
          `${styles.floatingButton} ${styles.cancelButton}`
        ) : (
          styles.floatingButton
        )
      }
      onClick={props.onClick}
      style={props.style || {}}
    >
      {props.image ? (
        <img src={props.image} alt={props.text} />
      ) : (
        <p style={props.textStyle || {}}>{props.text}</p>
      )}
      {props.label &&
        <div className={styles.labelContainer}>
          <p style={props.tooltipStyle || {}} className={styles.label}>
            {props.label}
          </p>
        </div>
      }
    </div>
  )
}

export default FloatingButton
